import React from 'react'
import Layout from '../layouts/Layout'
import hygine1 from '../images/hygine_1.jpeg'
import hygine2 from '../images/hygine_2.jpeg'
import SEO from '../components/SEO'

const HPage = () => {
  return (
    <Layout>
      <h1 className="text-4xl text-center mb-10 font-black">
        馬酔木からのご提案
      </h1>
      <div className="bg-white rounded-md p-6 mb-5">
        <h2 className="text-2xl mb-5 font-black">
          とんかつの店馬酔木の取り組み
        </h2>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
          <img
            className="w-full lg:w-2/5 rounded-md"
            src={hygine1}
            alt="ashibi-hygine"
          />
          <div className="leading-7 text-lg">
            <ul className="list-disc ml-5 lg:ml-3">
              <li>入り口には安全な非接触消毒体温計をご用意</li>
              <li>
                テーブルの間・カウンター・座敷の上り口・レジ回りに飛散防
                <br />
                止のパーティションの設置
              </li>
              <li>テーブルマットに使い捨てメニューを印刷</li>
              <li>食事中のマスクを入れるマスクキーパーをご用意</li>
              <li>トイレは自動水栓</li>
              <li>スタッフはマスク・手袋でご対応</li>
              <li>お箸は完全密閉タイプの割り箸に変更</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md p-6">
        <h2 className="text-2xl mb-5 font-black">
          三密を防ぐ為にも、下記ご協力ください。
        </h2>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
          <img
            className="w-full lg:w-2/5 rounded-md"
            alt="ashibi-hygine2"
            src={hygine2}
          />
          <div className="leading-7 text-lg">
            <ul className="list-disc ml-3">
              <li>
                席数を制限しておりますのでご来店前に必ずご予約をお願いします。
                <br />
                フリーのお客様の場合お断りすることもございます
              </li>
              <li>
                ご予約時間について
                <br />
                ご予約のお時間が重ならない様に、お時間の変更をお願いする場合がございます
              </li>
              <li>
                お座敷利用
                <br />
                小学生未満のお子様を含めず４名様までのご利用（最大６名様）
              </li>
              <li>５名様以上はパーティションで区切られた２テーブルでご対応</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="感染症対策について｜とんかつの店 馬酔木 横浜市都筑区" />
)

export default HPage
